<template>
  <div class="container">
    <form>
      <h2 class="text-center text-blue">Add Blog</h2>
      <small v-if="error" class="text-danger">{{ error }}</small>
      <div v-if="image" class="text-center">
        <img :src="image" class="profile-img" />
        <br /><br />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Blog Title"
          v-model="title"
        />
        <small v-if="titleError" class="text-danger">{{ titleError }}</small>
      </div>
      <br />
      <div class="form-group">
        <textarea
          maxlength="280"
          class="form-control"
          rows="4"
          placeholder="Blog Summary"
          v-model="summary"
        ></textarea>
        <small v-if="summaryError" class="text-danger">{{
          summaryError
        }}</small>
      </div>
      <br />
      <div class="form-group">
        <textarea
          class="form-control"
          rows="20"
          placeholder="Blog Content"
          v-model="body"
        ></textarea>
        <small v-if="bodyError" class="text-danger">{{ bodyError }}</small>
      </div>
      <br />
      <input
        ref="fileInput"
        type="file"
        accept=".png, .jpg, .jpeg"
        @input="pickFile"
      />
      <br />
      <small v-if="imageError" class="text-danger">{{ imageError }}</small>
      <div class="text-center">
        <button
          class="btn"
          :class="disabled ? 'disabled' : ''"
          @click.prevent="updateBlog"
        >
          PUBLISH
        </button>
      </div>
    </form>
    <br /><br />
  </div>
</template>

<script>
import { v4 as uuid4 } from "uuid";
import { mapGetters } from "vuex";

import { uploadImage } from "../../firebase";

export default {
  name: "EditBlog",
  computed: { ...mapGetters(["getBlog"]) },
  data() {
    return {
      error: "",
      image: null,
      imageExtension: null,
      imageError: "",
      title: "",
      titleError: "",
      summary: "",
      summaryError: "",
      body: "",
      bodyError: "",
      disabled: false,
    };
  },
  methods: {
    async updateBlog() {
      this.error = "";
      this.imageError = "";
      this.titleError = "";
      this.summaryError = "";
      this.bodyError = "";
      this.disabled = true;

      let errors = 0;

      if (!this.title.length || !this.title.replace(/\s/g, "").length) {
        errors++;
        this.titleError = "Title can not be empty.";
      }

      if (
        !this.summary.length ||
        this.summary.length > 280 ||
        !this.summary.replace(/\s/g, "").length
      ) {
        errors++;
        this.summaryError =
          "Summary can not be empty or have more than 280 characters.";
      }

      if (!this.body.length || !this.body.replace(/\s/g, "").length) {
        errors++;
        this.bodyError = "Blog content can not be empty.";
      }

      if (!this.image || !this.imageExtension) {
        errors++;
        this.imageError = "Please select and image for the blog.";
      }

      if (errors) {
        this.disabled = false;
        return;
      }

      let imageURL = this.image;

      const imageURLRegex = "https://firebasestorage.googleapis.com/";
      if (!this.image.includes(imageURLRegex)) {
        const imagePath = `profile/${uuid4()}.${this.imageExtension}`;
        const res = await uploadImage(this.image, imagePath);
        if (!res.isValid) {
          this.disabled = false;
          this.error = "An error has occured";
          return;
        }

        imageURL = res.imageURL;
      }

      const blog = {
        id: this.getBlog.id,
        body: {
          title: this.title,
          summary: this.summary,
          body: this.body.replaceAll("\n", "<br/>"),
          image: imageURL,
        },
      };

      const resp = await this.$store.dispatch("updateBlog", blog);
      if (!resp.isValid) {
        this.error = "An error has occured.";
        this.disabled = false;
        return;
      }

      this.error = "";
      this.image = null;
      this.imageExtension = null;
      this.imageError = "";
      this.title = "";
      this.titleError = "";
      this.summary = "";
      this.summaryError = "";
      this.body = "";
      this.bodyError = "";
      this.disabled = true;

      this.$router.push("/blogs");
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;

      if (file && file[0]) {
        this.imageExtension = file[0].name.slice(
          ((file[0].name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        let reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
  },
  mounted() {
    this.image = this.getBlog.image;
    this.title = this.getBlog.title;
    this.summary = this.getBlog.summary;
    this.body = this.getBlog.body.replaceAll("<br/>", "\n");
  },
};
</script>

<style scoped>
.btn {
  background-color: #023b95;
  color: white;
  border: none;
  border-radius: 30px;
  width: 40%;
  padding: 10px;
}

.profile-img {
  width: 80%;
}
</style>
